<template>      
    
    <div class="column center pointer">
      <apexchart width="500" type="donut" :options="options" :series="series"></apexchart>
    </div>
      
    
</template>

<script>

import { defineComponent } from 'vue';
export default defineComponent({
  data() {
    return {}
  },
  
  computed:{
    options() {
      return {        // series: [44, 55, 13, 33],
        labels: this.items.titles, // ['Apple', 'Mango', 'Orange', 'Watermelon', 'Nice'],        
        stroke: {
          show: true,
          curve: 'straight',
          // lineCap: 'butt',
          lineCap: 'round',
          colors: ['var(--back)'],
          // colors: ['none'],
          width: 10,
          dashArray: 2, 
      },
        legend:{
          show: true,
          showForSingleSeries: false,
          showForNullSeries: true,
          showForZeroSeries: true,
          position: 'bottom',
          horizontalAlign: 'center', 
          floating: true,
          fontSize: '13px',
          fontFamily: 'Helvetica, Arial',
          fontWeight: 400,
          formatter: undefined,
          inverseOrder: false,
          width: undefined,
          height: undefined,
          tooltipHoverFormatter: undefined,
          customLegendItems: [],
          offsetX: 0,
          offsetY: 30,
          labels: {
              colors: 'var(--g3)',
              // useSeriesColors: true
          },
        },
        plotOptions: {
          pie: {
            expandOnClick: false,
            donut: {
              size: '55%',
              background: 'transparent',
              labels: {
                show: true,
                name: {},
                value: {}
              }
            }
          }
        },        
        dataLabels: {
          style:{
            // colors: this.items.colors, 
          },
          enabled: true,
          formatter: function (val) {
            return Math.round(val) + "%"
          },
          dropShadow: {}
        }
      }
    },

    series(){
      return this.items.series
    },

    items(){
      let map = this.dict.stats.types || {}
      console.log('by category', map)
      console.log('roots', this.dict.roots)
      let titles = []
      let series = []
      let colors = []
      for (var key in map){        
        let id = Number(key)
        let value = map[key]
        if (value < 1) continue
        if (!id) continue
        let root = this.dict.rootmap[id]
        if (!root) continue
        titles.push(root.title)                
        series.push(map[key].total)
        if (!root.settings) continue
        colors.push(root.settings.color)
      }
      console.log('result',{titles,series,colors})
      return {titles,series,colors}
    },
    
  },

  methods:{
    // 


  },
  });


</script>

<style scoped>
  
</style>