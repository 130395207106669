<template>      
    <br>
    <br>
    
    <first/>
    <stat/>
    <about/>
    <card/>
    <crypto/>
    <planet/>
    
</template>
<script setup>
import planet from '@/page/planet.vue';
import about from './about.vue';
import card from './card.vue';
import crypto from './crypto.vue';
import first from './first.vue';
import stat from './stats.vue';
</script>
<script>
import { defineComponent } from 'vue';
export default defineComponent({
  data() {
    return {
      
    }
  },
  mounted(){
    // 
  },

  methods:{
// 
  },
  });


</script>

<style scoped>
  

  .popupAsset{
    position: absolute;
    left: 50%;transform: translateX(-50%);
    /* width: fit-content; */
    /* height: fit-content; */
    -webkit-backdrop-filter: saturate(180%) blur(20px);backdrop-filter: saturate(180%) blur(20px);
    z-index: 100;
  }


  .slider{
    position: absolute; 
    z-index: 100; 
    right: 50px; 
    top: 0px; 
    width: 400px;
    height: 100vh;
    background-color: rgba(255, 255, 255, 0);
    padding: 20px;
    border-radius: 20px;
    z-index: 100;
  }

  .sliderbox{
    /* width: 30vw; */
    height: 100vh;
    position: relative;
    overflow-y: scroll;
  }

  .card{
    position: relative;

  }

  /* will */
.will-enter-active {animation: will .5s ease-out}
.will-leave-active {animation: will .5s ease-out reverse}
@keyframes will {
    0% {opacity: .1; transform: translateX(0);}    
    100% {opacity: 1; transform: translateX(1);}
}

/* will */
.fader-enter-active {animation: fader 1.5s ease-out}
.fader-leave-active {animation: fader 1.5s ease-out reverse}
@keyframes fader {
    0% {opacity: .0; }    
    100% {opacity: 1;}
}
</style>