<template>      
      <div class="desktop screen column" style="overflow: hidden; width: 100vw; height: 100vh;">
              
        <div class="row">
       
            <div class="column left" style="margin-right: 5vw;">
              <p class="mini">Crypto</p>
              <br>
              <p class="title w600">STIMA is a crypto-currency that allows for the convertion of an asset into our token and back to an asset in our pool of available assets.</p>
              <br>
             
              <div class="row">
                <a class="linkcolor mr8" href="/about/white">White Paper</a>
                <a class="linkcolor" href="/about/presentation">Presentation</a>
              </div>
              
            </div>    


            <img src="/images/landing/one/convert.png" style="width: auto;height: 25vh;">
            
          </div>
      </div>
      
      
      <div class="mobile screen column" style="overflow: hidden; width: 100vw; height: 100vh;">
              
        <div class="column center">

          <svg width="151" height="175" viewBox="0 0 151 175" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M99.5835 73.1185L74.9341 87.4923L0 43.8027V43.7718L75.045 0L149.988 43.7264L110.887 66.527L77.8711 47.2616C76.5768 46.5803 75.0722 46.4298 73.6708 46.8416C72.2694 47.2533 71.0794 48.1954 70.349 49.4715C69.6185 50.7476 69.404 52.2592 69.7501 53.6911C70.0962 55.1231 70.9762 56.3649 72.2066 57.1576L99.5835 73.1185Z" fill="#C5C5C5"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M74.9346 174.935L75.0459 175L150.066 131.215V102.549L99.5533 73.1L74.9346 87.4549V116.117L77.872 117.83C79.1024 118.622 79.9824 119.864 80.3285 121.296C80.6746 122.728 80.4601 124.24 79.7296 125.516C78.9992 126.792 77.8092 127.734 76.4078 128.146C75.9247 128.288 75.4294 128.363 74.9346 128.372V174.935ZM149.959 43.709L110.857 66.5088L150.066 89.3879V43.7715L149.959 43.709Z" fill="#C5C5C5"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M0 131.239L75.045 174.999L75.0819 174.977V128.367C74.092 128.412 73.0993 128.195 72.2066 127.725L0 85.6231V131.239ZM75.0819 116.203L0 72.4367V43.7706L0.0822503 43.7227L75.0819 87.4541V116.203Z" fill="#C5C5C5"/>
</svg>

<br>
<br>
       
            <div class="column center">
              
              <p class="titleMobile" style="padding: 0 40px;">STIMA is a crypto-currency that allows for the convertion of an asset into our token and back to an asset in our pool of available assets.</p>
              <br>
              <br>
             
              <div class="row">
                <a class="linkcolor mr8" href="/about/white">White Paper</a>
                <a class="linkcolor" href="/about/presentation">Presentation</a>
              </div>
              
            </div>    


            <!-- <img src="/images/landing/one/convert.png" style="width: auto;height: 25vh;"> -->
            
          </div>
      </div>


</template>

<script>
import { defineComponent } from 'vue';
export default defineComponent({
  data() {
    return {
      
    }
  },
  mounted(){
    // 
  },

  computed:{
    
  },

  methods:{

  },
  });


</script>

<style scoped>
  
.title{
  font-style: normal;
  font-weight: 200;
  font-size: 40px;
  line-height: 110%;
  letter-spacing: 0.03em;
  color: rgba(255, 255, 255, 0.745);
}

.titleMobile{
  font-style: normal;
  font-weight: 200;
  font-size: 30px;
  line-height: 120%;
  letter-spacing: 0.03em;
  color: rgba(255, 255, 255, 0.745);
}




.imagefade-enter-active {
  transition: opacity 1s;
}
.imagefade-leave-active {
  transition: opacity 2s;
}
.imagefade-enter-from, .imagefade-leave-to {
  opacity: 0;
}
.imagefade-enter-to {
  opacity: 1;
}


.buttonfade-enter-active {
  transition: opacity 2s;
}
.buttonfade-leave-active {
  transition: opacity 1s;
}
.buttonfade-enter-from, .buttonfade-leave-to {
  opacity: 0;
}
.buttonfade-enter-to {
  opacity: 1;
}



.titlefade-enter-active {
  transition: opacity 2s;
}
.titlefade-leave-active {
  transition: opacity 1.5s;
}
.titlefade-enter-from, .titlefade-leave-to {
  opacity: 0;
}
.titlefade-enter-to {
  opacity: 1;
}
</style>