<template>
    <div class="row ch">
        <img :style="{width, height}" :src="icon">
        <span style="margin-left: 10px;">{{ name }}</span>
    </div>
</template>
<script>

/*

    <City rect width="24px" height="24px" :country="item.country" :city="item.city" :state="item.state" />
 
*/

export default {
    data: function () {return {
        // value: Boolean,
    }},
    props:{
        width: {type:String, default:'18px'},
        height: {type:String, default:'18px'},
        country: String,
        city: String,
        state: String,        
        rect: Boolean,        
    },
    mounted() {
        // this.value = this.active
    },
    computed: {
        icon(){
            if (this.rect) return `/images/icons/flags/rect/${this.country}.svg`
            return `/images/icons/flags/round/${this.country}.svg`
        },
        name(){            
            if (this.state) return `${this.city}, ${this.state.toUpperCase()}`
            return `${this.city}, ${this.dict.countryName(this.country)}`
        },
    },    
    methods: {
        // click(){            
        //     this.$emit('update:modelValue', !this.modelValue)
        //     this.$emit('update', true);
        // }
    },
}
</script>

<style scoped>
  
</style>

