<template>      
    <simple/>
</template>

<script setup>
import simple from '@/page/landing/simple/index.vue';
</script>

<script>

import { defineComponent } from 'vue';

export default defineComponent({
  data() {
    return {      
    }
  },
  mounted(){
    // 
  },

  methods:{
// 
  },
  });


</script>
