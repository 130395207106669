<template>

<!-- attr -->
  <Block>
    
    <Title plus @plus="add = true">{{title}}<template #desc>{{ desc }}</template></Title>
          
          <table v-if="items.length" style="margin-bottom: 10px;" >
            <tr class="bold">
              <td>Active</td>
              <td>Must</td>
              <td>Page</td>
              <td>Title</td>
              <td>Type</td>              
              <td title="Allow multi values like features etc">Multi</td>
              <td title="Allow user input value for this field">User</td>
              <!-- <td title="Has dict for this field">List</td> -->
              <td title="Field description for form">Desc</td>
              <td title="Placeholder for text fields">Placeholder</td>
              <td title="Sort fields by group in form">Group</td>
              <td title="iOS system icon name symbol (SF Symbols)">Symbol</td>
              <td class="t2">sid</td>
              <td class="t2">delete</td>
              
            </tr>

            <tr class="hover" v-for="(item, index) in items" :key="index">              
              <td class="ccc"><Check v-model="item.active" @update="dict.attr.update(item.id, 'active', item.active)"></Check></td>
              <td class="ccc"><Check v-model="item.must" @update="dict.attr.update(item.id, 'must', item.must)"></Check></td>
              <td class="ccc"><Check v-model="item.page" @update="dict.attr.update(item.id, 'page', item.page)"></Check></td>
              <td class="hover"><input class="tableInput" placeholder="title..." type="text" v-model="item.title" @blur="dict.attr.update(item.id, 'title', item.title)"></td>                  
              <td class="hover select">                
                <select class="tableselect t3" :style="{color: typeColor(item.type)}" v-model="item.type" @change="updateType(item)">        
                  <optgroup label="Standart">
                    <option :title="fieldtype.desc" v-for="(fieldtype, typeindex) in dict.types.filter(p => p.standart)" :key="typeindex" :value="fieldtype.id">{{fieldtype.title}}</option>                  
                  </optgroup>
                  <optgroup label="Files">
                    <option :title="fieldtype.desc" v-for="(fieldtype, typeindex) in dict.types.filter(p => p.files)" :key="typeindex" :value="fieldtype.id">{{fieldtype.title}}</option>                  
                  </optgroup>
                  <optgroup label="Location">
                    <option :title="fieldtype.desc" v-for="(fieldtype, typeindex) in dict.types.filter(p => p.location)" :key="typeindex" :value="fieldtype.id">{{fieldtype.title}}</option>                  
                  </optgroup>
                  <optgroup label="Special">
                    <option :title="fieldtype.desc" v-for="(fieldtype, typeindex) in dict.types.filter(p => p.special)" :key="typeindex" :value="fieldtype.id">{{fieldtype.title}}</option>                  
                  </optgroup>
                  <optgroup label="Metrics">
                    <option :title="fieldtype.desc" v-for="(fieldtype, typeindex) in dict.types.filter(p => p.metric)" :key="typeindex" :value="fieldtype.id">{{fieldtype.title}}</option>                  
                  </optgroup>
                  
                </select>                  
              </td>    
              
              <td class="ccc"><Check v-if="enabled(item, 'multi')" v-model="item.multi" @update="dict.attr.update(item.id, 'multi', item.multi); dict.attr.update(item.id, 'custom', false)"></Check></td>
              <td class="ccc"><Check v-if="enabled(item, 'custom')" v-model="item.custom" @update="dict.attr.update(item.id, 'custom', item.custom);dict.attr.update(item.id, 'multi', false)"></Check></td>
              <!-- <td class="ccc"><Check v-if="enabled(item, 'dict')" v-model="item.dict" @update="dict.attr.update(item.id, 'dict', item.dict)"></Check></td> -->
              <td class="hover"><input class="tableInput" placeholder="desc..." type="text" v-model="item.line" @blur="dict.attr.update(item.id, 'line', item.line)"></td>              
              <td class="hover" :class="{disable: !enabled(item, 'placeholder')}"><input class="tableInput" placeholder="placeholder..." type="text" v-model="item.placeholder" @blur="dict.attr.update(item.id, 'placeholder', item.placeholder)"></td>                            
              <td class="hover"><input class="tableInput" placeholder="form group name..." type="text" v-model="item.groups" @blur="dict.attr.update(item.id, 'groups', item.groups)"></td>                            
              <td class="hover"><input class="tableInput" placeholder="symbol" type="text" v-model=" item.meta.symbol" @blur="dict.attr.updateMeta(item.id, 'symbol', item.meta.symbol)"></td>                            
              <td class="t2" >{{ item.name }}</td>
              <td class="cc click pointer bad hover" @click="dict.attr.delete(item.id)">Delete</td>
            </tr>
          </table>



        <!-- create attr -->
        <Popup v-model="add" plus>
            <!-- <template #icon><svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M45 17.5L41.475 13.975L25.625 29.825L29.15 33.35L45 17.5ZM55.6 13.975L29.15 40.425L18.7 30L15.175 33.525L29.15 47.5L59.15 17.5L55.6 13.975ZM1.025 33.525L15 47.5L18.525 43.975L4.575 30L1.025 33.525Z" fill="var(--special)"/></svg></template>             -->
            <template #title>Add attributes</template>      
            <template #line>Write list each on new line</template>
            <div class="column w600">              
              <textarea v-model="list" class="w600" style="height: 300px;" :placeholder="`Color\nSize\nBody\netc...`"></textarea>
              <br>
              <div class="row center">                
                <p class="tag" @click="create()">Add</p>
              </div>
            </div>            
        </Popup>
    
  </Block>

  <!-- <Block v-if="items.length">         -->
    <!-- <Title>Save<template #desc>Just for dev proposition</template></Title> -->
    <!-- <p>{{ itemsTitleList }}</p>  -->
    <p class="tag" @click="copyText(itemsTitleList)">Copy list</p>
  <!-- </Block> -->

</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  mounted() {    
            //  
  },
  props:{
    root: Number, //dict id
    id: Number, //dict id
    title: {type:String, default:'Attributes'}, //dict id
    desc: {type:String, default:'Custom attributes. Specify what fields must be only for this asset'}, //dict id
  },

  data() {
    return {      
      add: false,
      list: undefined,
    }
  },
  computed: {

      items(){return this.dict.attr.list(this.id)},
      itemsTitleList(){
        if (!this.items) return []
        let list = []
        this.items.forEach(item => {
            list.push(item.title)
        });
        return list.join(', ')
      },
  },
  methods: {

    typeColor(type){
      let typeItem = this.dict.getType(type) //{field}
      if (typeItem.standart) return '#6D67B5'
      if (typeItem.special) return  '#AD6069'//purple
      if (typeItem.files) return '#28BBCF' //blue
      if (typeItem.metric) return '#B79F54' //yellow
      if (typeItem.location) return '#6CB97D'  //green
    },
    typeItem(type){
      return this.dict.getType(type)      
    },

    updateType(item){
      
      let id = item.id
      let type = item.type
      if (!id) return
      let typeItem = this.dict.getType(type) //{field}
      let y = []
      let n = []

      if (typeItem.files) n = ['multi','custom']
      if (typeItem.metric) n = ['multi','custom']
      if (typeItem.location) n = ['multi','custom']
      if (typeItem.standart) n = ['multi','custom']
      
      // custom, req, dict, multi
      switch (type) {
        case 'cat': {n=['multi', 'custom']; break}
        case 'list': {
          if (type == 'multi') n = ['custom'] 
          if (type == 'custom') n = ['multi']           
          break
        }                
      }

      let keys = {type}
      y.forEach(k => keys[k] = true);
      n.forEach(k => keys[k] = false);
      this.dict.attr.updates(id, keys)
    },

    enabled(item, fieldname){
      let type = item.type
      let typeItem = this.dict.getType(type) //{field}
      // custom, req, dict, multi
      let y = []

      // if (typeItem.location) y = []
      // if (typeItem.metric) y = []
      if (typeItem.files) y = []
      // if (typeItem.special) y = []
      // if (typeItem.standart) y = []

      switch (type) {
        // case 'switch':    {y=[]; break}        
        // case 'date':      {y=[]; break}                          
        case 'list': {y=['multi', 'custom']; break}                          
      }

      return y.includes(fieldname)      
    },

    async create(){      
      if (!this.list) return      
      let res = await this.dict.attr.create(this.root, this.id, this.list)
      // lazy error
      this.add = false
      this.list = undefined
    },

    

    


  },
});
</script>
<style scoped>

</style>