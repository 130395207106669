<template>      
    <div class="screen debug" style="position: relative;">
      
    <div class="row">
      <div style="position: relative; z-index: 1; height: 50vh;" id="globeViz"></div>
    </div>  
    <p @click="addnew()" class="button" style="z-index: 1000; position: absolute; bottom: 30px; right: 30px;">Add new</p>
      
    
      <!-- stats -->
      <Stats/>
      
      
      <!-- plate -->
      <!-- <div class="slider">
        
        <div class="sliderbox w300">        
          
          <div class="card row w400 padding" v-for="(item, index) in items" :key="index">          
            <div :style="{backgroundImage: `url(${item.image})`}" style="width: 200px; height: 100px; background-size: cover;"></div>
            <img :src="item.image" style="max-width: 200px; border-radius: 25px;">
            <div class="column padding">
              <h4>{{ item.title }}</h4>          
              <p class="desc">USD {{ tools.format(item.usd) }}</p>          
              <p class="desc">STIMA {{ tools.format(item.stima) }}</p>          
            </div>
          </div>      
        </div>
      </div> -->

      <!-- create attr -->
      <Transition name="fader">
        <div class="popupAsset" v-if="appendNew">
          <div class="row w600" style="position: relative; width: fit-content;">   
                
                <img :src="current.image" style="max-height: 300px; border-radius: 25px;">
                <div class="column padding w200">
                  <h3>{{ current.title }}</h3>
                  <p class="desc">USD {{ tools.format(current.usd, '.')  }}</p>
                  <p class="desc">STIMA {{ tools.format(current.stima, '.') }}</p>
                </div>
                
                
              </div>            
        </div>
      </Transition>
     
      

    </div>
    
</template>

<script>
import confetti from "canvas-confetti";
import Globe from 'globe.gl';
import { defineComponent } from 'vue';

export default defineComponent({
  data() {
    return {      
      showLogin: false,

      current: undefined,
      appendNew: false,
      globe: undefined,
      globedata:[],
      count:{
        min:0,
        max:10000000,
      },
      items:[
          {
            id: 4535989082,
            title:'Ferrari F80',
            image:'https://www.ferrarimoscow.ru/ferrarimoscow/wp-content/uploads/2020/04/4.png',
            created: 1718904242128,
            lat: -176.1926,
            long: 51.8306,
            usd: 450000,
            stima: 450000,            
        },
      ],
    }
  },
  mounted(){
    
    this.createGlobe()
    

  },

  methods:{

    createGlobe(){
      const markerSvg = `<svg viewBox="-4 0 36 36">
        <path fill="currentColor" d="M14,0 C21.732,0 28,5.641 28,12.6 C28,23.963 14,36 14,36 C14,36 0,24.064 0,12.6 C0,5.641 6.268,0 14,0 Z"></path>
        <circle fill="black" cx="14" cy="14" r="7"></circle>
      </svg>`;

      // Gen random data
      const N = 30;
      this.globedata = [...Array(N).keys()].map(() => ({
        lat: (Math.random() - 0.5) * 180,
        lng: (Math.random() - 0.5) * 360,
        size: 7 + Math.random() * 30,
        pop: Math.round( Math.random() * 3000000),
        // color: ['red', 'white', 'blue', 'green'][Math.round(Math.random() * 3)]
        color: ['#FF59A9']
      }));

      this.count.max = Math.max(...this.globedata.map(o => o.pop))
      this.count.min = Math.min(...this.globedata.map(o => o.pop))
      console.log('min',this.count.min)
      console.log('max',this.count.max)
      
      

      // const weightColor = this.dd3.scaleSequential(this.dd3.interpolateYlOrRd).domain([0, 1e7]);
      // const weightColor = d3.scaleSequential().interpolator(d3.interpolateBlue).domain([0, 1e7]);
      const weightColor = (d) => {

        // console.log('weightColor',d)
        return 'white'
      }

      let globe = Globe()
      globe        
        .showAtmosphere(false)
        .globeImageUrl('//unpkg.com/three-globe/example/img/earth-night.jpg')
        // .globeImageUrl('//unpkg.com/three-globe/example/img/earth-water.png')
        
        .bumpImageUrl('//unpkg.com/three-globe/example/img/earth-topology.png')
        // .backgroundImageUrl('//unpkg.com/three-globe/example/img/night-sky.png')
        .backgroundImageUrl('/images/planet/back.png')
        
        .hexBinPointsData(this.globedata)        
        // .htmlTransitionDuration(2000)
        // .pointOfView({ lat: 0, lng:0, altitude:1.1 }, [1000])
        .pointOfView({ lat: 0, lng:0, altitude:2 }, [1000])
        .hexBinPointWeight('pop')
        .hexAltitude(d => d.sumWeight * 6e-8)
        .hexBinResolution(4)
        .hexTopColor(d => weightColor(d.sumWeight))
        .hexSideColor(d => weightColor(d.sumWeight))
        .hexBinMerge(true)
        .enablePointerInteraction(false)(document.getElementById('globeViz'));

        globe.controls().autoRotate = true;
        globe.controls().autoRotateSpeed = 0.3;
        globe.controls().enable = false
        globe.controls().enableZoom = false
        
        this.globe = globe

    },
    addnew(){

      let newasset = {
            id: 4535989082,
            title:'Ferrari F80',
            image:'https://www.ferrarimoscow.ru/ferrarimoscow/wp-content/uploads/2020/04/4.png',
            created: 1718904242128,
            lat: -176.1926,
            long: 51.8306,
            usd: 450000,
            stima: 450000,            
        }
      this.items.push(newasset)

      let newpoint = {
        lat: (Math.random() - 0.5) * 180,
        lng: (Math.random() - 0.5) * 360,
        size: 7 + Math.random() * 30,    
        color: ['#FF59A9']
      }

      this.globedata.push(newpoint)      
      this.globe.htmlElementsData(this.globedata)

      this.previewNew(newasset)
      
      
    },
    
    
    previewNew(p){
      this.explode()
      this.current = p
      this.appendNew = true
      setTimeout(()=>{this.appendNew = false}, 3000)
      

      
    },


    explode(){
           confetti({
            particleCount: 350,
              spread: 200,
              gravity:.2,
              scalar:1,
          });
        },


  },
  });


</script>

<style scoped>
  

  .popupAsset{
    position: absolute;
    left: 50%;transform: translateX(-50%);
    /* width: fit-content; */
    /* height: fit-content; */
    -webkit-backdrop-filter: saturate(180%) blur(20px);backdrop-filter: saturate(180%) blur(20px);
    z-index: 100;
  }


  .slider{
    position: absolute; 
    z-index: 100; 
    right: 50px; 
    top: 0px; 
    width: 400px;
    height: 100vh;
    background-color: rgba(255, 255, 255, 0);
    padding: 20px;
    border-radius: 20px;
    z-index: 100;
  }

  .sliderbox{
    /* width: 30vw; */
    height: 100vh;
    position: relative;
    overflow-y: scroll;
  }

  .card{
    position: relative;

  }

  /* will */
.will-enter-active {animation: will .5s ease-out}
.will-leave-active {animation: will .5s ease-out reverse}
@keyframes will {
    0% {opacity: .1; transform: translateX(0);}    
    100% {opacity: 1; transform: translateX(1);}
}

/* will */
.fader-enter-active {animation: fader 1.5s ease-out}
.fader-leave-active {animation: fader 1.5s ease-out reverse}
@keyframes fader {
    0% {opacity: .0; }    
    100% {opacity: 1;}
}
</style>