import { mande } from 'mande';
const apipath = process.env.NODE_ENV == 'development' ? 'http://localhost:9999/api' : '/api'
// const apipath = process.env.NODE_ENV == 'development' ? 'http://192.168.0.102:9999/api' : '/api'
const request = mande(process.env.apihost || apipath)


// send
const send = async function(name, req){
  req = req || {}
  console.log('req', {name, req})
  try {    
    let resp = await request.post({name, req})
    console.log('resp', {name, resp})    
    return resp || {}

  } catch (error) {
    // console.error(error.body)
    return {error:{id:'conn', code: 500}}    
  }
};



// api
export default {

  async send(name,req) {return await send(name,req)},

  // support($route, $refs.supportMessage, console.logs)
  async support(route, message, logs) {return await send('support',{route, message, logs})},
  
  
  // news
  assets:{ 
    async create(news, channels) {return await send('asset/create',{news,channels})},
    async search(q) {return await send('asset/search',{q})},
    async update(id, k, v) {return await send('asset/update',{id, k,v})},
    async delete(id) {return await send('asset/delete',{id})},
  },
  
  
  // stats
  stats:{ 
    async global() {return await send('stats/global',{})},    
  },

  // news
  account:{ 
    async welcome(email) {return await send('account/welcome',{email})},
    async signup(email,pass,name) {return await send('account/signup',{email,pass,name})},
    // async update(id, k, v) {return await send('accounts/update',{id, k,v})},
    // async search(q) {return await send('accounts/search',{q})},
    // async delete(id) {return await send('accounts/delete',{id})},
  },

  image:{
    async delete(id, link) {return await send('assets/image/delete',{id, link})},
  },

  upload:{
    async logo(id, image){return await send('dict/upload/logo',{id, image})},
    // async image(id, image){return await send('upload/image',{id, image})},
    

    //file = image body or base64
    async image(file){
      return new Promise(function (resolve, reject) {
        const rs = new XMLHttpRequest();
        let host = `${apipath}/upload/image`;   
        console.log('upload/image',host)
        rs.open('post', host, true);
        rs.onload = function () {
          if (rs.status != 200)
            reject({ error: { id: 'try' } });
          if (!rs.response) resolve({});
          const resp = JSON.parse(rs.response);
          resolve(resp);
        };
        const uint8 = new Uint8Array(file);
        rs.send(uint8);
      });
    }, 

    async file(file){
      return new Promise(function (resolve, reject) {
        const rs = new XMLHttpRequest();
        let host = `${apipath}/upload/file`;        
        rs.open('post', host, true);
        rs.onload = function () {
          if (rs.status != 200)
            reject({ error: { id: 'try' } });
          if (!rs.response) resolve({});
          const resp = JSON.parse(rs.response);
          resolve(resp);
        };
        const uint8 = new Uint8Array(file);
        rs.send(uint8);
      });
    }, 

    async doc(file){
      return new Promise(function (resolve, reject) {
        const rs = new XMLHttpRequest();
        let host = `${apipath}/upload/doc`;        
        rs.open('post', host, true);
        rs.onload = function () {
          if (rs.status != 200)
            reject({ error: { id: 'try' } });
          if (!rs.response) resolve({});
          const resp = JSON.parse(rs.response);
          resolve(resp);
        };
        const uint8 = new Uint8Array(file);
        rs.send(uint8);
      });
    }, 
    
    
  },

  
  
}




